<form [formGroup]="formGroup">
  <div
    class="row px-4 grid-filters"
    [ngClass]="{
      'row-cols-2': checkboxElement != null
    }"
  >
    <div
      [ngClass]="{
        'col-10': checkboxElement != null
      }"
      class="pb-4 pt-2"
    >
      <div class="row row-cols-4">
        <ng-template ngFor let-form_elem [ngForOf]="filterElements">
          <div
            class="col"
            [ngClass]="form_elem.type === 'button' ? 'align-self-center' : 'pt-3'"
            [ngSwitch]="form_elem.type"
            [style.display]="
              form_elem.type !== 'domain' ? 'block' : displayDomains ? 'block' : 'none'
            "
          >
            <ng-template [ngSwitchCase]="'input'">
              <mat-form-field class="w-100">
                <input
                  matInput
                  placeholder="{{ form_elem.placeholder | translate }}"
                  formControlName="{{ form_elem.label }}"
                  (input)="onValueChange($event)"
                />
              </mat-form-field>
            </ng-template>

            <ng-template [ngSwitchCase]="'select'">
              <mat-form-field *ngIf="!form_elem.multiple" class="w-100">
                <mat-select
                  placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                  formControlName="{{ form_elem.label }}"
                  (selectionChange)="activateSubmit()"
                >
                  <mat-option *ngIf="form_elem.displayAll" [value]="ALL_ITEMS_DEFAULT_VALUE">
                    {{ 'common.all' | translate: { gender: form_elem.gender } }}</mat-option
                  >
                  <mat-option *ngIf="form_elem.allItemsLabel" [value]="form_elem.allItemsValue">
                    {{ form_elem.allItemsLabel | translate }}</mat-option
                  >
                  <ng-container *ngIf="!isMultilist(form_elem.options)">
                    <mat-option
                      *ngFor="let opt of form_elem.options"
                      [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                    >
                      {{ opt.label ? opt.label : opt.value ? opt.value : (opt | translate) }}
                    </mat-option>
                  </ng-container>
                  <ng-container *ngIf="isMultilist(form_elem.options)">
                    <ng-container *ngFor="let optg of form_elem.options; let index = index">
                      <mat-optgroup
                        *ngIf="optg != null && optg.length > 0"
                        [label]="
                          (form_elem.optionsGroupsLabel != null &&
                          form_elem.optionsGroupsLabel.length > 0
                            ? form_elem.optionsGroupsLabel[index]
                            : ''
                          ) | translate: { count: 2 }
                        "
                      >
                        <mat-option
                          *ngFor="let opt of optg"
                          [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                        >
                          {{
                            opt.label
                              ? (opt.label | translate)
                              : opt.value
                              ? (opt.value | translate)
                              : (opt | translate)
                          }}
                        </mat-option>
                      </mat-optgroup>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="form_elem.multiple" class="w-100">
                <mat-select
                  placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                  formControlName="{{ form_elem.label }}"
                  multiple
                  (selectionChange)="activateSubmit()"
                >
                  <ng-container *ngIf="!isMultilist(form_elem.options)">
                    <mat-option
                      *ngFor="let opt of form_elem.options"
                      [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                    >
                      {{
                        opt.label
                          ? (opt.label | translate)
                          : opt.value
                          ? (opt.value | translate)
                          : (opt | translate)
                      }}
                    </mat-option>
                  </ng-container>
                  <ng-container *ngIf="isMultilist(form_elem.options)">
                    <ng-container *ngFor="let optg of form_elem.options; let index = index">
                      <mat-optgroup
                        *ngIf="optg !== null && optg.length > 0"
                        [label]="
                          form_elem.optionsGroupsLabel !== null &&
                          form_elem.optionsGroupsLabel.length > 0
                            ? form_elem.optionsGroupsLabel[index]
                            : ''
                        "
                      >
                        <mat-option
                          *ngFor="let opt of optg"
                          [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                        >
                          {{
                            opt.label
                              ? (opt.label | translate)
                              : opt.value
                              ? (opt.value | translate)
                              : (opt | translate)
                          }}
                        </mat-option>
                      </mat-optgroup>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-template>

            <ng-template [ngSwitchCase]="'autocomplete-select'">
              <vertuoz-autocomplete-select
                [placeholder]="form_elem.placeholder | translate: { count: 2 }"
                [items]="form_elem.items"
                [selectedItem]="form_elem.selectedItem"
                (selectionChanged)="autoCompleteSelectValueChanged($event, form_elem.label)"
                [remoteMode]="form_elem.remoteMode"
                [resetCurentPageEvent]="form_elem.resetCurentPageEventForRemoteMode"
                [stopScrollEvent]="form_elem.stopScrollEventForRemoteMode"
                [pageSize]="form_elem.pageSizeForRemoteMode"
                (fetchData)="autoCompleteFetchData($event, form_elem.label)"
              ></vertuoz-autocomplete-select>
            </ng-template>

            <ng-template [ngSwitchCase]="'domain'">
              <mat-form-field
                *ngIf="!form_elem.multiple && form_elem.displayGeography"
                class="w-100"
              >
                <mat-select
                  placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                  formControlName="{{ form_elem.label }}"
                  (selectionChange)="activateSubmit()"
                >
                  <mat-option *ngIf="form_elem.allItemsLabel" [value]="form_elem.allItemsValue">
                    {{ form_elem.allItemsLabel | translate }}</mat-option
                  >
                  <mat-option
                    *ngFor="let opt of form_elem.options"
                    [value]="opt.perimeterItemDomainId"
                  >
                    {{ opt.label | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                *ngIf="!form_elem.multiple && !form_elem.displayGeography"
                class="w-100"
              >
                <mat-select
                  placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                  formControlName="{{ form_elem.label }}"
                  (selectionChange)="activateSubmit()"
                >
                  <mat-option *ngIf="form_elem.allItemsLabel" [value]="form_elem.allItemsValue">
                    {{ form_elem.allItemsLabel | translate }}</mat-option
                  >
                  <mat-option
                    *ngFor="let opt of form_elem.options | slice: 1:3"
                    [value]="opt.perimeterItemDomainId"
                  >
                    {{ opt.label | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>

            <ng-template [ngSwitchCase]="'datepicker'">
              <mat-form-field class="w-100">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  formControlName="{{ form_elem.label }}"
                  placeholder="{{ form_elem.placeholder | translate }}"
                  [min]="form_elem.minDate"
                  [max]="form_elem.maxDate"
                  (dateChange)="onValueChange($event.value, form_elem.label)"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
            </ng-template>
          </div>
        </ng-template>

        <ng-container *ngIf="secondaryFilterElements?.length > 0">
          <div class="col align-self-center" *ngIf="!displaySecondaryFilters">
            <button type="button" mat-stroked-button class="btn-sm">
              <mat-icon
                svgIcon="double_arrow_right"
                matTooltip="Afficher d'autres filtres"
                (click)="expand(true)"
              ></mat-icon>
            </button>
          </div>
          <ng-container *ngIf="displaySecondaryFilters">
            <ng-template ngFor let-form_elem [ngForOf]="secondaryFilterElements">
              <div
                class="col"
                [ngClass]="form_elem.type === 'button' ? 'align-self-center' : 'pt-3'"
                [ngSwitch]="form_elem.type"
                [style.display]="
                  form_elem.type !== 'domain' ? 'block' : displayDomains ? 'block' : 'none'
                "
              >
                <ng-template [ngSwitchCase]="'input'">
                  <mat-form-field class="w-100">
                    <input
                      matInput
                      placeholder="{{ form_elem.placeholder | translate }}"
                      formControlName="{{ form_elem.label }}"
                      (input)="onValueChange($event)"
                    />
                  </mat-form-field>
                </ng-template>

                <ng-template [ngSwitchCase]="'select'">
                  <mat-form-field *ngIf="!form_elem.multiple" class="w-100">
                    <mat-select
                      placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                      formControlName="{{ form_elem.label }}"
                      (selectionChange)="activateSubmit()"
                    >
                      <mat-option *ngIf="form_elem.displayAll" [value]="ALL_ITEMS_DEFAULT_VALUE">
                        {{ 'common.all' | translate: { gender: form_elem.gender } }}</mat-option
                      >
                      <mat-option *ngIf="form_elem.allItemsLabel" [value]="form_elem.allItemsValue">
                        {{ form_elem.allItemsLabel | translate }}</mat-option
                      >
                      <ng-container *ngIf="!isMultilist(form_elem.options)">
                        <mat-option
                          *ngFor="let opt of form_elem.options"
                          [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                        >
                          {{ opt.label ? opt.label : opt.value ? opt.value : (opt | translate) }}
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="isMultilist(form_elem.options)">
                        <ng-container *ngFor="let optg of form_elem.options; let index = index">
                          <mat-optgroup
                            *ngIf="optg != null && optg.length > 0"
                            [label]="
                              (form_elem.optionsGroupsLabel != null &&
                              form_elem.optionsGroupsLabel.length > 0
                                ? form_elem.optionsGroupsLabel[index]
                                : ''
                              ) | translate: { count: 2 }
                            "
                          >
                            <mat-option
                              *ngFor="let opt of optg"
                              [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                            >
                              {{
                                opt.label
                                  ? (opt.label | translate)
                                  : opt.value
                                  ? (opt.value | translate)
                                  : (opt | translate)
                              }}
                            </mat-option>
                          </mat-optgroup>
                        </ng-container>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field *ngIf="form_elem.multiple" class="w-100">
                    <mat-select
                      placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                      formControlName="{{ form_elem.label }}"
                      multiple
                      (selectionChange)="activateSubmit()"
                    >
                      <ng-container *ngIf="!isMultilist(form_elem.options)">
                        <mat-option
                          *ngFor="let opt of form_elem.options"
                          [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                        >
                          {{
                            opt.label
                              ? (opt.label | translate)
                              : opt.value
                              ? (opt.value | translate)
                              : (opt | translate)
                          }}
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="isMultilist(form_elem.options)">
                        <ng-container *ngFor="let optg of form_elem.options; let index = index">
                          <mat-optgroup
                            *ngIf="optg !== null && optg.length > 0"
                            [label]="
                              form_elem.optionsGroupsLabel !== null &&
                              form_elem.optionsGroupsLabel.length > 0
                                ? form_elem.optionsGroupsLabel[index]
                                : ''
                            "
                          >
                            <mat-option
                              *ngFor="let opt of optg"
                              [value]="form_elem.useKey ? opt[form_elem.useKey] : opt"
                            >
                              {{
                                opt.label
                                  ? (opt.label | translate)
                                  : opt.value
                                  ? (opt.value | translate)
                                  : (opt | translate)
                              }}
                            </mat-option>
                          </mat-optgroup>
                        </ng-container>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </ng-template>

                <ng-template [ngSwitchCase]="'autocomplete-select'">
                  <vertuoz-autocomplete-select
                    [placeholder]="form_elem.placeholder | translate: { count: 2 }"
                    [items]="form_elem.items"
                    [selectedItem]="form_elem.selectedItem"
                    (selectionChanged)="autoCompleteSelectValueChanged($event, form_elem.label)"
                    [remoteMode]="form_elem.remoteMode"
                    [resetCurentPageEvent]="form_elem.resetCurentPageEventForRemoteMode"
                    [stopScrollEvent]="form_elem.stopScrollEventForRemoteMode"
                    [pageSize]="form_elem.pageSizeForRemoteMode"
                    (fetchData)="autoCompleteFetchData($event, form_elem.label)"
                  ></vertuoz-autocomplete-select>
                </ng-template>

                <ng-template [ngSwitchCase]="'domain'">
                  <mat-form-field
                    *ngIf="!form_elem.multiple && form_elem.displayGeography"
                    class="w-100"
                  >
                    <mat-select
                      placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                      formControlName="{{ form_elem.label }}"
                      (selectionChange)="activateSubmit()"
                    >
                      <mat-option *ngIf="form_elem.allItemsLabel" [value]="form_elem.allItemsValue">
                        {{ form_elem.allItemsLabel | translate }}</mat-option
                      >
                      <mat-option
                        *ngFor="let opt of form_elem.options"
                        [value]="opt.perimeterItemDomainId"
                      >
                        {{ opt.label | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="!form_elem.multiple && !form_elem.displayGeography"
                    class="w-100"
                  >
                    <mat-select
                      placeholder="{{ form_elem.placeholder | translate: { count: 2 } }}"
                      formControlName="{{ form_elem.label }}"
                      (selectionChange)="activateSubmit()"
                    >
                      <mat-option *ngIf="form_elem.allItemsLabel" [value]="form_elem.allItemsValue">
                        {{ form_elem.allItemsLabel | translate }}</mat-option
                      >
                      <mat-option
                        *ngFor="let opt of form_elem.options | slice: 1:3"
                        [value]="opt.perimeterItemDomainId"
                      >
                        {{ opt.label | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-template>

                <ng-template [ngSwitchCase]="'datepicker'">
                  <mat-form-field class="w-100">
                    <input
                      matInput
                      [matDatepicker]="datePicker"
                      formControlName="{{ form_elem.label }}"
                      placeholder="{{ form_elem.placeholder | translate }}"
                      [min]="form_elem.minDate"
                      [max]="form_elem.maxDate"
                      (dateChange)="onValueChange($event.value, form_elem.label)"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                  </mat-form-field>
                </ng-template>
              </div>
            </ng-template>
            <div class="col-1 align-self-center">
              <button type="button" mat-stroked-button class="btn-sm">
                <mat-icon
                  svgIcon="double_arrow_left"
                  matTooltip="Cacher les filtres secondaires"
                  (click)="expand(false)"
                ></mat-icon>
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="buttonElement">
          <div class="col align-self-center">
            <button
              type="button"
              mat-flat-button
              color="primary"
              class="btn-sm"
              (click)="onSubmit()"
              [disabled]="!isSubmitActive"
            >
              {{ buttonElement.text | translate }}
            </button>
            <a id="reinit" color="primary" class="btn-sm" (click)="onReinit()">
              <mat-icon>filter_alt_off</mat-icon>
              Réinitialiser
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="grid-filters__perimeter col-2 pt-4" *ngIf="checkboxElement">
      <mat-checkbox (change)="onSubmit()" formControlName="{{ checkboxElement.label }}">
        {{ checkboxElement.placeholder | translate }}
      </mat-checkbox>
    </div>
  </div>
</form>

<hr class="grid-filters__separator" />
