import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import {
    FetchDataParamsModel,
    ItemModel
} from '@shared/components/vertuoz-autocomplete-select/vertuoz-autocomplete-select.component';

import { Label } from '@app/core/models/common/label.model';
import { DomainModel } from '@app/core/models/domain/domain.model';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { LabelModel } from '@app/features/property-data/models/comparison.model';
import { ALL_ITEMS_DEFAULT_VALUE } from '@app/shared/constants/common.enum';

@Component({
    selector: 'dynamic-form-filter',
    templateUrl: './dynamic-form-filter.component.html',
    styleUrls: ['./dynamic-form-filter.component.scss']
})
export class DynamicFormFilterComponent implements OnInit {
    public formGroup: FormGroup;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() formTemplate: any;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() submit = new EventEmitter();
    @Output() filterReinit = new EventEmitter();
    @Output() fetchRemoteDate = new EventEmitter<FetchDataParamsModel>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() onChangeValue = new EventEmitter<{ value: any; formCtrlName: string }>();
    // todo: avec display domains le composant n'est plus générique (prévoir intégrer ce type avec les types supportés)
    @Input() displayDomains = false;

    public isSubmitActive: boolean = false;
    public displaySecondaryFilters: boolean = false;

    // Elements
    public filterElements;
    public secondaryFilterElements;
    public checkboxElement = null;
    public buttonElement;

    public readonly ALL_ITEMS_DEFAULT_VALUE = ALL_ITEMS_DEFAULT_VALUE;

    constructor(private cdr: ChangeDetectorRef, public translate: TranslateService) {}

    ngOnInit(): void {
        const group = {};
        this.formTemplate.forEach(input_template => {
            group[input_template.label] = new FormControl('');
        });

        this.formGroup = new FormGroup(group);

        this.filterElements = this.formTemplate.filter(
            res =>
                res.type !== 'checkbox' && res.isSecondaryFilter === false && res.type != 'button'
        );

        this.secondaryFilterElements = this.formTemplate.filter(
            res => res.type !== 'checkbox' && res.isSecondaryFilter === true
        );

        this.checkboxElement = this.formTemplate.find(
            res => res.type === 'checkbox' && res.isSecondaryFilter === false
        );

        this.buttonElement = this.formTemplate.find(res => res.type === 'button');

        // Set all values by default for the SELECT elements
        const allElements = this.formTemplate.filter(
            res => (res.type === 'select' || res.type === 'domain') && res.multiple !== true
        );
        allElements.forEach(el => {
            if (
                el.displayAll ||
                el.allItemsValue === ALL_ITEMS_DEFAULT_VALUE ||
                el.options?.find(o => o?.id === ALL_ITEMS_DEFAULT_VALUE)
            ) {
                this.setValue(el.label, ALL_ITEMS_DEFAULT_VALUE);
            }
        });

        // Set all values for domain
    }

    onSubmit(): void {
        this.deactivateSubmit();
        this.submit.emit();
    }

    onReinit(): void {
        this.activateSubmit();
        this.filterReinit.emit();
    }

    expand(value: boolean): void {
        this.displaySecondaryFilters = value;
    }

    public setOptions(
        formControlName: string,
        value:
            | Array<string>
            | Array<Label>
            | Array<DomainModel>
            | Array<KeyValue<string, string>>
            | Array<number>
            | Array<Array<Label>>
            | Array<Array<LabelModel>>
    ): void {
        this.formTemplate.filter(x => x.label === formControlName)[0].options = [...value];
    }

    public updateItems(formControlName: string, values: Array<ItemModel>): void {
        const control = this.formTemplate.find(x => x.label === formControlName);
        if (control) {
            control.items = [...values];
        }
    }

    public setActivePerimeterOptions(formControlName: string, value: PerimeterScope): void {
        this.formTemplate.filter(x => x.label === formControlName).perimeterScope = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get(formControlName: string): any {
        const template = this.formTemplate.find(t => t.label === formControlName);

        // In this case where the list has an item == -1 (All)
        if (
            template &&
            template.type === 'select' &&
            (template.displayAll === true ||
                template.allItemsValue === ALL_ITEMS_DEFAULT_VALUE ||
                template.options?.find(o => o?.id === ALL_ITEMS_DEFAULT_VALUE) != null)
        ) {
            if (this.formGroup.value[formControlName] === ALL_ITEMS_DEFAULT_VALUE) {
                return null;
            } else {
                return this.formGroup.value[formControlName];
            }
        }

        // Return null when the text is empty
        if (template && template.type === 'input') {
            const value = this.formGroup.value[formControlName];
            return value == null || value.trim() === '' ? null : value;
        }

        return this.formGroup.value[formControlName];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setValue(formControlName: string, value: any): void {
        this.formGroup.get(formControlName).setValue(value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public patchValue(formControlName: string, value: any, options?: Object): void {
        // In the particular case for the autocomplete
        const template = this.formTemplate.find(t => t.label === formControlName);
        if (template && template.type === 'autocomplete-select') {
            if (value === null || value === undefined) {
                template.selectedItem = '';
            } else if (value instanceof Object) {
                template.selectedItem = <ItemModel>{
                    itemId: value.itemId,
                    itemLabel: value.itemLabel
                };
            } else {
                template.selectedItem = <ItemModel>{ itemId: 0, itemLabel: value };
            }
            this.formGroup.get(formControlName).patchValue(template.selectedItem, options);
        } else if (this.formGroup.get(formControlName)) {
            this.formGroup.get(formControlName).patchValue(value, options);
        }
        this.cdr.detectChanges();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isMultilist(form_elem: any): boolean {
        if (form_elem != null && Array.isArray(form_elem)) {
            if (form_elem.length > 0 && Array.isArray(form_elem[0])) {
                return true;
            }
        }
        return false;
    }

    autoCompleteSelectValueChanged($event: ItemModel, formElementLabel: string): void {
        this.formGroup.get(formElementLabel).setValue($event);
        const template = this.formTemplate.find(t => t.label === formElementLabel);
        template.selectedItem = $event;
        this.activateSubmit();
    }

    autoCompleteFetchData(params: FetchDataParamsModel, formElementLabel: string): void {
        params.formControlName = formElementLabel;
        this.fetchRemoteDate.emit(params);
    }

    public deactivateSubmit(): void {
        this.isSubmitActive = false;
    }

    public activateSubmit(): void {
        this.isSubmitActive = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onValueChange(event: any, formCtrlName: string = null): void {
        if (this.formGroup.valid) {
            this.activateSubmit();
        } else {
            this.deactivateSubmit();
        }

        // It is used for dates
        if (formCtrlName) {
            this.onChangeValue.emit({ value: event, formCtrlName: formCtrlName });
        }
    }
}
